*
{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}


:root
{
  /* Global Vars */
  --first-z-index: 1000000;
  --second-z-index: 100000;

  --font-family: 'Courier New', Courier, monospace;
  --font-family-cursive: Brush Script MT,
    Brush Script Std,
    cursive;


  /* Navbar */
  --navbar-height: 100px;
  --navbar-color: rgba(0,0,0,0.05);
}


body 
{
  margin: 0;
  padding: 0;
  z-index: var(--second-z-index);

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-image: url(./../public/Photos/background2.jpg);
  background-size: cover;
  background-repeat: repeat-y;

}

code 
{
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



ul
{
  list-style-type: none;
}



a
{
  text-decoration: none;
}



/*  
  ************************* Navbar/Start *************************
  ****************************************************************
*/
.navbar
{
  width: 95%;
  height: var(--navbar-height);

  background: rgba(230, 230, 230, 0.10);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);

  position: fixed;
  top: 10px;
  left: 50%;
  z-index: var(--first-z-index);

  transform: translate(-50%, 0);

  

  display: grid;
  grid-template-columns: 15% auto;
  justify-items: center;
  align-items: center;
}



.navbar-logo 
{
  border: solid 2px rgb(0, 0, 0);
  font-size: 1rem;
  font-weight: bold;
  color: black;
  background-color: transparent;
  margin-left: 25px;
  padding: 3px;
  justify-self: flex-start;

  display: grid;
  align-items: center;
  justify-items: center;
}



.navbar-center
{
  width: 80%;
  height: 50%;
}



.navbar-link-list
{
  width: 100%;
  height: 100%;
  background-color: inherit;

  display: flex;
  justify-content: space-evenly;
  align-items: center;
}



.navbar-link
{
  color: rgb(0, 0, 0);
  font-size: clamp(1rem, 1.5rem, 1.75rem);
  font-weight: bold;
  font-family: 'Courier New', Courier, monospace;
}



.navbar-link:hover
{
  color: rgb(255,255, 255);
}



.navbar-toggle-btn
{
  display: none;
  background-color: transparent;
}



.dropdown-menu
{
  width: 100%;
  height: 0px;

  align-self: center;
  display: none;

  border-radius: 0.5rem;
  box-shadow: 0px 8px 15px 5px rgba(1, 18, 247, 0.10);
  background-color: rgba(230, 230, 230, 0.2) !important;
  backdrop-filter: inherit;
  -webkit-backdrop-filter: inherit;
}



.dropdown-menu-links
{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 3px;

  background-color: inherit;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}



.dropdown-menu-link
{
  color: rgba(0,0,0,1);
  font-weight: bolder;
  font-family: var(--font-family);
  font-size: clamp(1rem, 1.25rem, 1.35rem);
}



.dropdown-menu-link:hover
{
  color:white;
}


/*  
  ************************** Navbar/End **************************
  ****************************************************************
*/



/****************************************************************************************/
/********************************** Home Page - Start ***********************************/
/****************************************************************************************/
.homepage
{
  /* margin-top: var(--navbar-height); */
  background-image: url(./../public/Photos/Home/IMG_E3570.JPG);
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100vh;
}


.homepage-section-title
{
  margin: 0px 0px 25px 15px;

  font-weight: bold;
  font-family: var(--font-family);
  font-style: bold;
  font-size: clamp(1.2rem, 10vw, 3.25rem);
  text-shadow: rgba(0, 0, 0, 0.25) 3px 5px 3px;
}



.homepage-header
{
  width: 100%;
  height: 100vh;

  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin-bottom: 50px;
}



.intro-container
{
  max-height: 275px;
  aspect-ratio: 1/1 !important;
  width: clamp(250px, 45vw, 575px);
  margin-left: 25px;
  margin-bottom: 10px;

  padding: 5px;
  border: none;

  display: flex;
  flex-grow: 0;
  flex-direction: column;
  row-gap: 15px;
  justify-content: center;
}



.intro-container:hover
{
  border-radius: 0.75rem;
  background-color: rgba(255, 255, 255, 0.28);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}



.homepage-title
{
  font-weight: bolder;
  font-family: var(--font-family);
  font-size: clamp(1.2rem, 10vw, 3.25rem);
  color: rgb(245, 245, 245);
  /* text-shadow: rgba(192, 192, 192, 0.55) 3px 3px 3px; */
  text-align: left;
}



.homepage-intro
{
  width: 75%;
  height: auto;
  background-color: transparent;
  text-align: left;
  vertical-align: middle;

  font-size: clamp(1rem, 2vw, 1.25rem);
  font-family: var(--font-family);
  font-weight: bold;
  color: rgba(245, 245, 245);
}



.homepage-img-container
{
  width: 100%;
  height: auto;
  display: grid;

  grid-template-columns: repeat(auto-fit, 300px);
  justify-items: center;
  justify-content: center;
  align-items: center;
  row-gap: 50px;
}


.homepage-img
{
  box-shadow: 5px 5px 5px 5px rgba(0,0,0,0.15);
}


.homepage-img:hover
{
  transform: scale(1.05);
  box-shadow: 7px 7px 7px 7px rgba(0, 0, 0, 0.45);
}



.homepage-info-section
{
  width: 100%;
  height: auto;
  padding: 15px;
  margin-top: 50px;

  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: minmax(200px, 300px) minmax(200px, 300px);
  row-gap: 25px;
  justify-items: center;
  align-items: center;
}



.home-info-article
{
  width: 95%;
  height: 95%;
  min-height: 275px;
  padding: 3px;

  background-color: rgba(145, 145, 146, 0.15);
  backdrop-filter: blur(2px);

  border-style: outset;
  border-color: rgba(97, 96, 96, 0.15);
  border-radius: 0.75rem;

  box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.15);

  display: flex;
  flex-direction: column;
  justify-content: center;
}



.home-info-article:hover 
{
  background-color: rgba(45, 45, 45, 0.20);
  box-shadow: 7px 7px 7px 7px rgba(0, 0, 0, 0.75);
}



.home-info-title
{
  font-family: var(--font-family);
  font-size: clamp(1.5rem, 10vw, 2.5rem);
  font-weight: bolder;
  text-align: center;
  text-shadow: rgba(0, 0, 0, 0.25) 3px 5px 3px;
}



.home-info-desc
{
  width: 95%;
  text-align: center;
  align-self: center;
  font-size: clamp(0.95rem, 5vw, 1rem);
}



/****************************************************************************************/
/*********************************** Home Page - End*************************************/
/****************************************************************************************/




/****************************************************************************************/
/********************************** Art Page - Start ************************************/
/****************************************************************************************/
.art-page
{
  width: 100vw;
  height: 100%;
}


.art-page-header
{
  background-image: url(./../public/Photos/Art_Page/background.JPG);
  background-position: center;
  background-size: cover;

  height: 100vh;
  width: 100vw;

  display: flex;
  justify-content: left;
  align-items: flex-end;
}



.art-page-header-center
{
  display: inline-block;
  max-height: 275px;
  aspect-ratio: 1 / 1 !important;
  width: clamp(250px, 45vw, 575px);
  margin: 0px 0px 10px 25px;

  color: white;
  font-size: 2.5rem;
  font-weight: bold;

  display: flex;
  justify-content: left;
  align-items: bottom;
}


.art-page-main
{
  margin: 25px 0px 100px 0px;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;

  row-gap: 50px;
}



.artist-article-name
{
  display: block;
  margin-left: 10px;
  width: 100%;

  font-family: var(--font-family);
  font-size: clamp(2.5rem, 10vw, 3rem);
  font-weight: bold;

  letter-spacing: 5px;
  color: dodgerblue;
  text-shadow: rgba(0, 0, 0, 0.15) 3px 5px 3px;
}



.artist-img-container
{
  width: 100%;
  height: auto;
  margin-top: 15px;

  display: grid;
  grid-template-columns: repeat(auto-fit, 300px);
  justify-items: center;
  justify-content: center;
  align-items: center;
  row-gap: 50px;
}


.artist-card
{
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}


.artist-card img
{
  max-height: 225px;
  box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.15);
}


.artist-card img:hover
{
  transform: scale(1.1);
  box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.45);
}


.artist-card span
{
  font-family: var(--font-family);
  font-weight: bold;
  /* font-size:  */
}

/****************************************************************************************/
/************************************ Art Page - End*************************************/
/****************************************************************************************/



/****************************************************************************************/
/********************************** About Page - Start **********************************/
/****************************************************************************************/
.about-header
{
  height: 100vh;
  width: 100%;

  background-image: url(./../public/Photos/Art_Page/background.JPG);
  background-size: cover;
  background-position: center;

  display: flex;
  align-items: flex-end;
}



.about-main
{
  width: 100%;
  height: auto;

  padding-block: 50px;

  display: grid;
  justify-content: center;
  grid-template-columns: 400px 200px 200px;
  column-gap: 10px;
  grid-template-rows: repeat(4, 200px);
  row-gap: 10px;
  grid-template-areas:
    "image1 desc desc"
    "image1 desc desc"
    "image1 image2 image2"
    "image1 image2 image2";
}



.about-desc
{
  width: 95%;
  max-width: 500px;
  height: auto;
  padding: 10px;

  font-family: var(--font-family);
  font-weight: bold;
  color: rgb(6, 6, 6);

  background-color: rgba(244, 244, 244, 0.35);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);


  box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.15);

  grid-area: desc;
  align-self: center;
  justify-self: center;
}


.about-main-img
{
  border-radius: 0.95rem;
}



#about-img-1
{
  grid-area: image1;
  width: 100%;
  height: 100%;

  background-image: url('./../public/Photos/About/image_1.JPG');
  background-size: cover;
  background-position: center;
}



#about-img-2 
{
  display: block;
  grid-area: image2;
  width: 100%;
  height: 100%;
  background-image: url('./../public/Photos/About/image_2.JPG');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
}


/****************************************************************************************/
/*********************************** About Page - End ***********************************/
/****************************************************************************************/




/****************************************************************************************/
/********************************* Contact Page - Start *********************************/
/****************************************************************************************/
.contact-page-main
{
  width: 100%;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  background-image: url(./../public//Photos/Art_Page/background.JPG);
  background-size: cover;
  background-position: center;
}



.contact-form
{
  width: 95%;
  max-width: 500px;
  min-height: 275px;
  height: auto;
  padding: 10px;

  background-color: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(4px);
  border-radius: 0.75rem;
  

  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  row-gap: 10px;
}



.form-input
{
  width: 100%;
  border-radius: 0.25rem;
  border-color: rgba(255, 255, 255);
  padding: 2px;
  background-color: rgba(255, 255, 255, 0.5);
  font-weight: bold;
}



.form-message
{
  padding: 3px;
  width: 100%;
  border-radius: 0.25rem;
  border: inset rgba(255, 255, 255) 2px;

  color: #000000;
  font-weight: bold;
  
  background-color: rgba(255, 255, 255, 0.5);
}



.form-submit-btn
{
  width: 100px;
  align-self: end;
  border-radius: 0.25rem;
  font-weight: bold;
  background-color: rgba(255, 255, 255, 0.75);
}



.form-submit-btn:hover
{
  background-color: rgba(0,0,0,0.75);
  color: rgba(255, 255, 255);
}




/****************************************************************************************/
/********************************** Contact Page - End **********************************/
/****************************************************************************************/




/****************************************************************************************/
/*********************************** Media Queries **************************************/
/****************************************************************************************/
@media screen and (max-width: 750px)
{
  /*  
    ************************* Navbar/Start *************************
    ****************************************************************
  */
  .navbar
  {
    grid-template-columns: 1fr 1fr;
  }



  .navbar-center
  {
    display: none;
  }



  .navbar-logo span
  {
    text-align: center;
  }



  .navbar-toggle-btn
  {
    border: none;

    font-size: 2rem;
    font-weight: bold;
    margin-right: 25px;

    display: block;
    justify-self: flex-end;
  }



  .dropdown-menu
  {
    display: block;
    height: 0px;
    overflow: hidden;

    position: absolute;
    top: var(--navbar-height);
    left: 0px;

    transition: height linear 500ms;
  }



  .dropdown-menu-list 
  {
    display: block;
  }


  /* .homepage
  {
    margin-top: var(--navbar-height);
  } */


  .homepage-intro
  {
    width: 95%;
  }



  .intro-container 
  {
    margin-left: 5px;
  }



  .homepage-img-temp
  {
    aspect-ratio: 2/3;
    width: 95%;
  }

  .homepage-info-section
  {
    grid-template-columns: repeat(auto-fit, 100%);
    grid-template-rows: auto;
  }



  .home-info-article
  {
    height: auto;
  }



  .about-main 
  {
    background-image: url('./../public/Photos/About/image_2.JPG');
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;

    grid-template-columns: 1fr;
    column-gap: 0px;
    grid-template-rows: 1fr;
    row-gap: 0px;
    
  }



  .about-main-img
  {
    display: none;
  }
}